import type {
  PageRef,
  ComponentRef,
  RouterRef,
} from '@wix/platform-editor-sdk';
import { createWidgetDefinition } from './createWidgetDefinition';
import { createSectionDefinition } from './createSectionDefinition';
import { InstallAppData, PresetWidget } from '../types/types';
import {
  classicDefaultInstallationSize,
  defaultBreakpoints,
} from '../const/pagesConsts';
import { FlowEditorSDK } from '@wix/yoshi-flow-editor';

function delay(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export const createAppPage = async ({
  flowAPI,
  editorSDK,
  isResponsive,
  isStudio,
  appDefId,
  pageData,
  shouldAddMenuItem = false,
  shouldNavigateToPage = false,
  t,
}: InstallAppData): Promise<PageRef> => {
  const ppPageRef = await addWidgetAsPage({
    flowAPI,
    editorSDK,
    isResponsive,
    isStudio,
    appDefId,
    pageData,
    shouldAddMenuItem,
    shouldNavigateToPage,
    t,
  });
  if (!isResponsive || isStudio) {
    await editorSDK.document.transactions.runAndWaitForApproval(
      '',
      async () => {
        await addWidgetWithPresets({
          flowAPI,
          editorSDK,
          ppPageRef,
          pageData,
          isStudio,
        });
      },
    );
  }
  return ppPageRef;
};

const addWidgetAsPage = async ({
  flowAPI,
  editorSDK,
  isResponsive,
  isStudio,
  appDefId,
  pageData,
  shouldAddMenuItem = false,
  shouldNavigateToPage = false,
  t,
}: InstallAppData): Promise<PageRef> => {
  const { title, pageId, widgetId, presetId, mobilePresetId, pageUriSEO } =
    pageData;

  let pageRef;
  try {
    pageRef = await editorSDK.document.transactions.runAndWaitForApproval(
      'token',
      async () => {
        const addedPageRef = await editorSDK.pages.add('', {
          title: t(title),
          definition: {
            type: 'Page',
            ...(isResponsive && !isStudio
              ? {
                  components: [
                    createSectionDefinition([
                      createWidgetDefinition({
                        appDefinitionId: appDefId,
                        widgetId,
                        presetId,
                        mobilePresetId,
                      }),
                    ]) as any,
                  ],
                }
              : {}),
            data: {
              managingAppDefId: appDefId,
              tpaPageId: pageId,
              pageUriSEO,
            },
            // @ts-expect-error
            breakpoints:
              isResponsive && !isStudio ? defaultBreakpoints : undefined,
            componentType: 'mobile.core.components.Page',
          },
          shouldAddMenuItem,
          shouldNavigateToPage,
        });
        return addedPageRef;
      },
    );
    await editorSDK.document.transactions.runAndWaitForApproval(
      'token',
      async () => {
        pageRef &&
          (await editorSDK.document.pages.setState('', {
            state: { [pageId]: [pageRef] },
          }));
      },
    );
  } catch (error: any) {
    flowAPI.errorMonitor.captureException(error, {
      tags: { actionName: 'addWidgetAsPage', pageId: pageData.pageId },
    });
    throw error;
  }
  return pageRef;
};

const addWidgetWithPresets = async ({
  flowAPI,
  editorSDK,
  ppPageRef,
  pageData,
  isStudio,
}: PresetWidget): Promise<void> => {
  try {
    if (isStudio) {
      await editorSDK.pages.navigateTo('', { pageRef: ppPageRef });
      await delay(1000);
    }

    const { widgetId, presetId, mobilePresetId } = pageData;

    const [, studioSectionRef] =
      await editorSDK.document.components.getChildren('', {
        componentRef: ppPageRef as ComponentRef,
      });

    await editorSDK.application.appStudioWidgets.addWidget('', {
      widgetId,
      layout: {
        docked: {
          left: {
            px: 0,
            vw: 0,
          },
          right: {
            px: 0,
            vw: 0,
          },
        },
        x: 0,
        y: 0,
        fixedPosition: false,
        width: classicDefaultInstallationSize.width,
        height: classicDefaultInstallationSize.height,
        scale: 1,
        rotationInDegrees: 0,
      } as any,
      layouts: {
        componentLayout: {
          hidden: false,
          height: {
            type: 'auto',
          },
          minWidth: {
            type: 'px',
            value: 300,
          },
          minHeight: {
            type: 'px',
            value: 300,
          },
          type: 'ComponentLayout',
          width: {
            type: 'percentage',
            value: 100,
          },
        },
        itemLayout: {
          id: '',
          type: 'GridItemLayout',
          gridArea: {
            columnStart: 1,
            columnEnd: 2,
            rowStart: 1,
            rowEnd: 2,
          },
          alignSelf: 'start',
          justifySelf: 'start',
          margins: {
            left: {
              type: 'percentage',
              value: 0,
            },
            top: {
              type: 'px',
              value: 0,
            },
          },
        },
        containerLayout: {
          type: 'GridContainerLayout',
          rows: [
            {
              type: 'fr',
              value: 1,
            },
          ],
          columns: [
            {
              type: 'fr',
              value: 1,
            },
          ],
        },
      },
      scopedPresets: {
        desktop: { layout: presetId, style: presetId },
        mobile: { layout: mobilePresetId, style: mobilePresetId },
      },
      installationType: 'closed',
      containerRef: isStudio ? studioSectionRef : (ppPageRef as ComponentRef),
    });
  } catch (error: any) {
    flowAPI.errorMonitor.captureException(error, {
      tags: { actionName: 'addWidgetWithPresets', pageId: pageData.pageId },
    });
    throw error;
  }
};

export const initPortfolioRouterPrefix = async (
  editorSDK: FlowEditorSDK,
  _prefix: string,
): Promise<RouterRef> => {
  const portfolioPagesData = await editorSDK.pages.getApplicationPages('');
  let prefix = _prefix;
  let checkValidity = true;
  let i = 1;
  while (checkValidity) {
    const isValidPrefix = !(
      await editorSDK.routers.isValidPrefix('', { prefix })
    ).valid;
    const existingRouter = !!(await editorSDK.routers.getByPrefix('', {
      prefix,
    }));
    checkValidity = isValidPrefix || existingRouter;
    if (checkValidity) {
      prefix = _prefix + i;
      i++;
    } else {
      checkValidity = false;
    }
  }
  const config = { portfolioPagesData };
  const routerRef = await editorSDK.routers.add('', {
    prefix,
    config,
  });
  return routerRef;
};

export const addPortfolioPageToMenu = async (
  editorSDK: FlowEditorSDK,
  portfolioRouterRef: string,
  label: string,
) => {
  const menuId = await editorSDK.menu.getDefaultMenuId('');
  await editorSDK.document.menu.addItem('', {
    menuId,
    menuItem: {
      link: {
        innerRoute: '/',
        routerId: portfolioRouterRef,
        target: '_self',
        type: 'DynamicPageLink',
      },
      label,
    },
  });
};

export const addPageToRouter = async (
  editorSDK: FlowEditorSDK,
  routerRef: RouterRef,
  pageRef: PageRef,
  pageRoles: string,
): Promise<void> => {
  await editorSDK.document.routers.pages.connect('', {
    pageRef,
    routerRef,
    pageRoles: [pageRoles],
  });
};
